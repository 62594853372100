<template>
  <div class="asset" :isOpen="true">
    <div slot="trigger" @click="opened = !opened">
      <div class="top-tag">
        <DragSVG />
        <span>Click and drag to reorder</span>
      </div>
      <div class="customTrigger" :class="{ divider: asset.type === 'Divider' }">
        <h2 v-if="asset.type !== 'Divider'">
          {{ asset.type }}
          <span v-if="asset.type !== 'Text' && asset.type !== 'Divider'" :class="{ 'no-title': asset.name === '' }"
            >| <span :class="{ italic: asset.title === '' }">{{ asset.title || 'No Title' }}</span></span
          >
        </h2>
        <CaratSVG :class="[opened ? 'opened' : '', asset.type === 'Divider' ? 'hide' : '']" />
      </div>
    </div>

    <div v-if="opened" class="content">
      <div v-if="asset.type !== 'Text' && asset.type !== 'Divider'" class="title">
        <h1>{{ asset.type }} Title</h1>
        <input type="text" v-model.lazy="asset.title" :placeholder="placeholder" />
        <p class="description">
          {{
            `Provide the title of this ${
              this.asset.type.toLowerCase() || 'asset'
            } as you’d like it to appear in the course.`
          }}
        </p>
      </div>

      <!-- WBT URL -->
      <div v-if="asset.type === 'Web-Based Training'" class="wbt">
        <h1>URL*</h1>
        <input type="text" v-model.lazy="asset.assetURL" placeholder="http://www.vw.com" />
        <p class="description">Include the link address (URL) for this training. It should come from the CRC.</p>
      </div>

      <!-- PDF Upload -->
      <div v-if="asset.type === 'PDF'" class="pdf">
        <div class="banner">
          <div class="upload" :class="{ activeTab: tabIndex === 0 }" @click="tabIndex = 0">
            Upload from Your Device
          </div>
          <div class="link" :class="{ activeTab: tabIndex === 1 }" @click="tabIndex = 1">PDF URL</div>
        </div>
        <div v-if="tabIndex === 0" class="upload-inner">
          <h1>PDF Upload*</h1>
          <div class="upload-icon">
            <span v-if="!file && !tooLarge && !fileName && !asset.fileName">No file selected</span>
            <span class="danger" v-else-if="tooLarge">File is too large</span>
            <span v-else
              >{{ fileName || asset.fileName }} <RedXIcon v-if="fileName || asset.fileName" @click="remove"
            /></span>
            <UploadIcon /> Upload
            {{ isNew() }}
          </div>
          <input id="upload-input" type="file" accept="application/pdf" @change="onFileSelected" />
          <PDFThumbnail v-if="fileSrc || asset.filePath" />
          <p class="description">PDFs cannot be larger than 20 MB.</p>
        </div>
        <div v-else-if="tabIndex === 1" class="link-inner">
          <h1>PDF URL*</h1>
          <input type="text" v-model.lazy="asset.assetURL" />
          <p class="description">Include the link address (URL) for this pdf.</p>
        </div>
      </div>

      <!-- Image Upload -->
      <div v-else-if="asset.type === 'Image'" class="image">
        <div class="banner">
          <div class="upload" :class="{ activeTab: tabIndex === 0 }" @click="tabIndex = 0">
            Upload from Your Device
          </div>
          <div class="link" :class="{ activeTab: tabIndex === 1 }" @click="tabIndex = 1">Image URL</div>
        </div>
        <div v-if="tabIndex === 0" id="upload-inner">
          <h1>Image Upload*</h1>
          <div class="upload-icon">
            <span v-if="!file && !tooLarge && !asset.fileName">No file selected</span>
            <span class="danger" v-else-if="tooLarge">File is too large</span
            ><span v-else
              >{{ fileName || asset.fileName }} <RedXIcon v-if="fileName || asset.fileName" @click="remove"
            /></span>
            <UploadIcon /> Upload
            {{ isNew() }}
          </div>
          <input
            id="upload-input"
            type="file"
            accept="image/png, image/jpeg, image/jpeg, image/gif"
            @change="onFileSelected"
          />
          <img v-if="fileSrc" id="preview" :src="fileSrc" />
          <SignedImage
            v-else-if="asset.filePath"
            :asset="asset"
            :courseID="courseID"
            :moduleBeingEdited="moduleBeingEdited"
            :sectionBeingEdited="sectionBeingEdited"
            :assetIndex="assetIndex"
          />
          <p class="description">Images cannot be larger than 20 MB. Allowable file types: JPG, JPEG, PNG, GIF</p>
        </div>
        <div v-else-if="tabIndex === 1" class="link-inner">
          <h1>URL*</h1>
          <input type="text" v-model.lazy="asset.assetURL" />
          <p class="description">Include the link address (URL) for this image.</p>
        </div>
      </div>

      <!-- Video Upload -->
      <div v-else-if="asset.type === 'Video'" class="video">
        <div class="banner">
          <div class="upload" :class="{ activeTab: tabIndex === 0 }" @click="tabIndex = 0">
            Upload from Your Device
          </div>
          <div class="link" :class="{ activeTab: tabIndex === 1 }" @click="tabIndex = 1">Video URL</div>
        </div>
        <div v-if="tabIndex === 0" id="upload-inner">
          <h1>Video Upload*</h1>
          <div class="upload-icon">
            <span v-if="!file && !tooLarge && !asset.fileName">No file selected</span>
            <span class="danger" v-else-if="tooLarge">File is too large</span
            ><span v-else
              >{{ fileName || asset.fileName }} <RedXIcon v-if="fileName || asset.fileName" @click="remove"
            /></span>
            <UploadIcon /> Upload
            {{ isNew() }}
          </div>
          <input id="upload-input" type="file" accept="video/mp4" @change="onFileSelected" />
          <SignedImage
            v-if="fileSrc || asset.filePath"
            :asset="asset"
            :courseID="courseID"
            :moduleBeingEdited="moduleBeingEdited"
            :sectionBeingEdited="sectionBeingEdited"
            :assetIndex="assetIndex"
          />
          <!-- <VideoThumbnail v-if="fileSrc || asset.filePath" /> -->
          <p class="description">Video cannot be larger than 5 GB.</p>
        </div>
        <div v-else-if="tabIndex === 1" class="link-inner">
          <h1>URL*</h1>
          <input type="text" v-model.lazy="asset.assetURL" :class="{ notUnique: !validURL }" />
          <p v-if="validURL" class="description">Include the link address (URL) for this video.</p>
          <p v-else class="description">Must be a valid youtube or vimeo link</p>
        </div>
      </div>

      <!-- Link URL-->
      <div v-if="asset.type === 'Link'" class="link">
        <h1>URL*</h1>
        <input type="text" v-model.lazy="asset.assetURL" placeholder="http://www.vw.com" />
        <p class="description">Include the link address (URL).</p>
      </div>

      <!-- Slideshow Upload -->
      <div v-if="asset.type === 'Slideshow'" class="image">
        <div class="banner">
          <div class="upload" :class="{ activeTab: tabIndex === 0 }" @click="tabIndex = 0">
            Upload from Your Device
          </div>
          <div class="link" :class="{ activeTab: tabIndex === 1 }" @click="tabIndex = 1">Slideshow URL</div>
        </div>
        <div v-if="tabIndex === 0" id="upload-inner">
          <h1>Slideshow Upload*</h1>
          <div class="upload-icon">
            <span v-if="!file && !tooLarge && !asset.fileName">No file selected</span>
            <span class="danger" v-else-if="tooLarge">File is too large</span
            ><span v-else
              >{{ fileName || asset.fileName }} <RedXIcon v-if="fileName || asset.fileName" @click="remove"
            /></span>
            <UploadIcon /> Upload
            {{ isNew() }}
          </div>
          <input id="upload-input" type="file" accept=".pptx, .ppt" @change="onFileSelected" />
          <SlideshowThumbnail v-if="fileSrc || asset.filePath" />
          <p class="description">Slideshows cannot be larger than 20 MB. Allowable file types: PPT, PPTX, KEY</p>
        </div>
        <div v-else-if="tabIndex === 1" class="link-inner">
          <h1>URL*</h1>
          <input type="text" v-model.lazy="asset.assetURL" />
          <p class="description">Include the link address (URL) for this slideshow.</p>
        </div>
      </div>

      <!-- Divider -->
      <div v-if="asset.type === 'Divider'" class="divider">
        <div class="hr"></div>
      </div>

      <!-- Guides -->
      <div v-if="asset.type !== 'Divider'" class="guides">
        <div class="banner">
          <div class="participant-guide" :class="{ activeTab: guideIndex === 0 }" @click="guideIndex = 0">
            Participant Guide
          </div>
          <div class="instructor-guide" :class="{ activeTab: guideIndex === 1 }" @click="guideIndex = 1">
            Instructor Guide
          </div>
        </div>
        <div v-if="guideIndex === 0" class="participant-guide-inner">
          <h1 v-if="asset.type !== 'Text'">{{ `${asset.type}` }} Description</h1>
          <div class="wysiwyg">
            <ckeditor
              v-model="asset.participantDescription"
              :config="editorConfig"
              @focus="editorFocused = true"
              @blur="editorFocused = false"
            ></ckeditor>
          </div>
          <p class="description">
            {{ `Include meaningful notes on this ${asset.type}. These will be visible to participants.` }}
          </p>
        </div>
        <div v-else-if="guideIndex === 1" class="instructor-guide-inner">
          <h1 v-if="asset.type !== 'Text'">{{ `${asset.type}` }} Instructor Notes</h1>
          <div class="wysiwyg">
            <ckeditor
              v-model="asset.instructorDescription"
              :config="editorConfig"
              @focus="editorFocused = true"
              @blur="editorFocused = false"
            ></ckeditor>
          </div>
          <p class="description">
            {{ `Include meaningful notes on this ${asset.type}. These will only be visible to instructors.` }}
          </p>
        </div>
      </div>

      <div class="removeAsset" @click="$emit('removeAsset', asset.sequence)">
        <RedXIcon />
        <div class="text">Remove {{ asset.type }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import 'vue-collapsible-component/lib/vue-collapsible.css';
// import Collapsible from 'vue-collapsible-component';
import CaratSVG from '@/assets/icons/carat.svg';
import DragSVG from '@/assets/icons/drag.svg';
import RedXIcon from '@/assets/icons/x.svg';
import VModal from 'vue-js-modal';
import PDFThumbnail from '@/assets/icons/pdf_thumbnail.svg';
// import VideoThumbnail from '@/assets/icons/link_thumbnail.svg';
import SlideshowThumbnail from '@/assets/icons/slideshow_thumbnail.svg';
import UploadIcon from '@/assets/icons/upload_icon.svg';
import SignedImage from '@/components/Thumbnail.vue';
import axios from 'axios';
// import Dropzone from '@/components/Dropzone.vue';

import Vue from 'vue';
import CKEditor from 'ckeditor4-vue/dist/legacy';

Vue.use(VModal);

Vue.use(CKEditor);

export default {
  props: ['asset', 'courseID', 'moduleBeingEdited', 'sectionBeingEdited', 'assetIndex'],
  methods: {
    saveProgress() {
      this.$emit('saveProgress');
    },
    removeAsset() {
      this.$emit('removeAsset');
    },
    remove() {
      this.fileSrc = null;
      this.file = null;
      this.fileName = null;
      this.fileSrc = null;
      this.fileType = null;
      this.asset.fileName = '';
      this.asset.filePath = '';
      this.asset.thumbnail = '';
    },
    // showDeleteAssetModal() {
    //   this.$modal.show('delete-asset-modal');
    // },
    // hideModal() {
    //   this.$modal.hide('delete-asset-modal');
    // },
    // checkDelete() {
    //   if (this.del.toUpperCase() === 'DELETE') {
    //     this.removeAsset();
    //     this.hideModal();
    //   }
    // },
    isNew() {
      if (this.file) {
        return 'New';
      }
      return this.asset.type;
    },
    onFileSelected(event) {
      if (
        (this.asset.type === 'Video' && event.target.files[0].size > 5242880000) ||
        (this.asset.type !== 'Video' && event.target.files[0].size > 20971520)
      ) {
        this.tooLarge = true;
      } else {
        this.tooLarge = false;
        // eslint-disable-next-line prefer-destructuring
        this.file = event.target.files[0];
        this.fileName = this.file.name;
        this.fileType = this.file.type;
        // this.getPresignedURL();
        if (
          this.asset.type === 'Image' ||
          this.asset.type === 'PDF' ||
          this.asset.type === 'Video' ||
          this.asset.type === 'Slideshow'
        ) {
          const reader = new FileReader();
          reader.readAsDataURL(this.file);
          reader.onload = (e) => {
            const binary = e.srcElement.result;
            const x = binary.split(';');
            const fileType = x[0].substring(5, x[0].length);
            // get presigned URL
            axios
              .post(
                `${process.env.VUE_APP_API_URL}/media`,
                { contentType: fileType },
                {
                  headers: { authorization: sessionStorage.getItem('vwcup-token') },
                },
              )
              .then((response) => {
                // handle success
                const { url } = response.data.data;
                const { keyName } = response.data.data;
                const { thumbnail } = response.data.data;
                this.asset.filePath = keyName;
                this.asset.fileName = this.fileName;
                this.asset.thumbnail = thumbnail;
                // PUT to presigned url
                axios
                  .put(url, this.file, { headers: { 'Content-Type': fileType } })
                  .then((response2) => {
                    console.error(response2);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              })
              .catch((error) => {
                // handle error
                console.error(error);
              });
            this.fileSrc = e.target.result;
          };
        } else {
          this.fileSrc = 'x';
        }
        this.saveProgress();
        // get course, use asset ID to get thumbnail and use as src
      }
    },
  },
  watch: {
    editorFocused: {
      handler() {
        this.$emit('sectionEditorFocused', this.editorFocused);
      },
      deep: true,
    },
  },
  computed: {
    placeholder() {
      return `${this.asset.type} Title`;
    },
    isDivider() {
      return this.asset.type === 'Divider';
    },
    validURL() {
      if (this.asset.type === 'Video' && this.asset.assetURL) {
        return this.asset.assetURL.includes('youtube.com') || this.asset.assetURL.includes('vimeo.com');
      }
      return true;
    },
  },
  data() {
    return {
      editorFocused: false,
      tooLarge: false,
      opened: true,
      tabIndex: 0,
      guideIndex: 0,
      del: '',
      file: null,
      fileName: null,
      fileSrc: null,
      fileType: null,
      fileDone: true,
      presignedURL: null,
      keyName: null,
      // wysiwyg config
      editorConfig: {
        toolbarGroups: [
          { name: 'clipboard', groups: ['undo'] },
          { name: 'styles', groups: ['styles'] },
          { name: 'basicstyles', groups: ['bold, italic'] },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'align'],
          },
        ],
        removeButtons: 'Cut,Copy,Paste,Underline,Strike,Subscript,Superscript,PasteFromWord,PasteText,Styles',
        removePlugins: 'PasteFromWord,',
        pasteAsPlainText: false,
      },
      //
    };
  },
  components: {
    // Collapsible,
    CaratSVG,
    DragSVG,
    RedXIcon,
    PDFThumbnail,
    // VideoThumbnail,
    SlideshowThumbnail,
    UploadIcon,
    SignedImage,
    // Dropzone,
  },
};
</script>

<style lang="scss" scoped>
.asset {
  width: calc(100% + 30px);
  margin: 35px 0 25px 0;
  background: $primary-white;
  border: 1px dashed $grey;
  border-radius: 3px;
  ::v-deep {
    .customTrigger {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-family: $head-font-stack;
      height: 90px;
      // line-height: 90px;
      padding: 0 27px;
      > h2 {
        margin: 0;
        font-size: 1.4rem;
        > .no-title {
          font-style: italic;
        }
      }
      > svg {
        width: 20px;
        transform: rotate(180deg);
        margin: 0 10px 0 auto;
        transition: transform 0.3s ease-in-out;
        cursor: pointer;
        &.opened {
          transform: rotate(0deg);
          transition: transform 0.3s ease-in-out;
        }
        &.hide {
          opacity: 0;
        }
      }
      &.divider {
        height: 20px;
      }
    }
    .content {
      padding: 0 27px;
    }
    .Collapsible__trigger:focus {
      outline: none !important;
    }
  }
  .top-tag {
    cursor: pointer;
    height: 15px;
    border-radius: 3px;
    border: 1px dashed $grey;
    border-bottom: none;
    margin-top: -20px;
    display: flex;
    z-index: 100;
    background: $primary-white;
    width: 175px;
    margin-left: 25px;
    padding-top: 5px;
    > svg {
      margin: 0 10px;
      width: 12px;
      cursor: pointer;
    }
    span {
      font-family: $head-font-stack;
      font-size: 12px;
      margin-right: 10px;
    }
  }
  h1 {
    font-family: $head-font-stack;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 1rem;
  }
  input {
    width: 95%;
    margin-bottom: 5px;
    &.notUnique {
      border-color: $danger;
    }
  }
  .description {
    font-weight: 300;
    font-size: 1rem;
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .hr {
    width: 105%;
    height: 0px;
    border-bottom: 1px solid $light-grey;
    margin: 19px 0 25px 0;
  }
  .removeAsset {
    display: flex;
    font-weight: 700;
    color: $danger;
    font-size: 1.4rem;
    align-items: center;
    width: calc(100%);
    margin-bottom: 35px;
    cursor: pointer;
    > svg {
      width: 15px;
      margin: 0 8px 0 auto;
      cursor: pointer;
      ::v-deep {
        g {
          stroke: $danger;
        }
      }
    }
  }
  .banner {
    height: 35px;
    display: flex;
    line-height: 35px;
    font-size: 1.4rem;
    font-family: $head-font-stack;
    text-align: center;
    margin-bottom: 25px;
    width: calc(100% + 54px);
    margin-left: -27px;
    margin-top: 25px;
    cursor: pointer;
    > div {
      width: 50%;
      background: $light-grey;
      color: $primary-dark;
      &.activeTab {
        background: $primary-dark;
        color: $primary-white;
      }
    }
  }
  .divider {
    .hr {
      border-color: $primary-black;
      width: 100%;
    }
  }
  .guides {
    // margin-top: 25px;
  }
  .wysiwyg {
    width: 100%;
    margin-bottom: 5px;
    &.hide {
      display: none;
    }
  }
}
::v-deep {
  .vm--modal {
    width: 501px;
    text-align: center;
    box-shadow: 0px 2px 6px #0000001a;
    .modal-header {
      margin-top: 35px;
      font-size: 2rem;
      font-family: $head-font-stack;
      font-weight: 300;
      padding: 0 70px;
    }
    .red {
      margin-top: 10px;
      font-weight: 700;
      color: $danger;
      font-size: 1.4rem;
      padding: 0 70px;
    }
    .delete {
      margin-top: 25px;
    }
    input {
      width: 362px;
    }
    .primary-btn {
      width: 157px;
      margin: 25px auto 0 auto;
    }
    .secondary-btn {
      width: 157px;
      margin: 5px auto 0 auto;
      color: $primary-light;
      font-weight: 700;
      width: 157px;
      font-size: 1.4rem;
      background: $primary-white;
    }
  }
}
#upload-input {
  height: auto !important;
  border: none !important;
  padding: 0 !important;
  border: none !important;
  margin-top: -20px;
  opacity: 0;
  width: 30%;
  display: block;
  margin-left: auto;
  height: 20px;
  cursor: pointer;
}

#preview {
  max-width: 100%;
}

.upload-inner {
  svg {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
}

.upload-icon {
  display: flex;
  width: 100%;
  color: $primary-light;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: right;
  justify-content: space-between;
  // cursor: pointer;
  margin-bottom: -15px;
  margin-top: 15px;
  span {
    color: $primary-black;
    font-weight: 300;
    margin: 0 auto 0 0;
  }
  svg {
    width: 15px;
    height: 21px !important;
    margin-right: 5px;
    margin-bottom: -4px;
    cursor: pointer;
    ::v-deep {
      g {
        stroke: $danger;
      }
    }
  }
  .danger {
    color: $danger;
  }
}
.italic {
  font-style: italic;
}
</style>
